<template>
    <BT-Blade-Item
        bladeName="courier-billable-item"
        :bladesData="bladesData"
        navigation="courier-billable-items"
        title="Courier's Billed Item">
        <template slot-scope="{ item }">
            <v-text-field
                disabled
                label="Courier"
                v-model="item.seller.companyName"
                prepend-icon="mdi-map-marker-distance" />

            <BT-Date-Picker
                disabled
                v-model="item.dateOfItem"
                label="Date" />

            <v-text-field
                disabled
                label="Distance (Km)"
                v-model.number="item.distance"
                type="number"
                prepend-icon="mdi-map-marker-distance" />
            
            <v-list>
                <v-subheader>Packages</v-subheader>
                <v-list-item v-for="(packaged, index) in item.billablePackagedItems" :key="index">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ packaged.measurementStandard }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ packaged | toMeasurementLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{ packaged.quantity | toDisplayNumber }}
                    </v-list-item-action>
                </v-list-item>
            </v-list>

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Courier-Billable-Item',
    props: {
        bladesData: null
    }
}
</script>